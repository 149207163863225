const reconciliationNew = {
  state: {
    pageType: '',
    moduleType: 'transaction',
    beginUpdate: new Date(),
    companyId: 0,
    publisherId: 0,
    transactionReconciliationPageNumber: 1,
    transactionReconciliationQuery: {
      executeStatus: -1,
      queryString: '',
      startDate: '',
      endDate: '',
      companyId: 0
    }, // 交易对账查询条件

    contractId: 0, // 合同明细

    transactionSettlementQuery: {
      agentCompanyId: 0, // 代理商公司id
      billStatus: -1, // 结算单状态
      startDate: '',
      endDate: ''
    }, // 交易结算查询条件
    transactionSettlementNumber: 1,

    maintenanceReconciliationQuery: {
      companyId: 0, // 结算公司id
      status: -1, // 对账状态
      startDate: '',
      endDate: '',
      keyword: '',
      taskitemType: -1,
      resourcetypeId: -1
    }, // 运维对账查询条件
    notAllowReconciliationQuantity: 0, // 不允许对账条目的勾选数量

    maintenanceSettlementNumber: 1,
    maintenanceSettlementQuery: {
      billStatus: -1, // 结算单状态
      startDate: '',
      endDate: '',
      supplierCompanyId: 0,
      purchaserCompanyId: 0
    }, // 运维结算查询条件
    feeitemIds: [], // 计费项主键
    billId: 0// 结算单主键
  },
  mutations: {
    set_reconciliationNew_pageType (state, data) {
      state.pageType = data
    },
    set_reconciliationNew_moduleType (state, data) {
      state.moduleType = data
    },
    set_reconciliationNew_publisherId (state, data) {
      state.publisherId = data
    },
    set_reconciliationNew_companyId (state, data) {
      state.companyId = data
    },
    set_reconciliationNew_update (state, data) {
      state.beginUpdate = data
    },
    set_reconciliationNew_transactionReconciliationQuery (state, data) {
      state.transactionReconciliationQuery = data
    },
    set_reconciliationNew_transactionReconciliationPageNumber (state, data) {
      state.transactionReconciliationPageNumber = data
    },
    set_reconciliationNew_contractId (state, data) {
      state.contractId = data
    },
    set_reconciliationNew_transactionSettlementNumber (state, data) {
      state.transactionSettlementNumber = data
    },
    set_reconciliationNew_transactionSettlementQuery (state, data) {
      state.transactionSettlementQuery = data
    },
    set_reconciliationNew_maintenanceReconciliationQuery (state, data) {
      state.maintenanceReconciliationQuery = data
    },
    set_reconciliationNew_maintenanceSettlementNumber (state, data) {
      state.maintenanceSettlementNumber = data
    },
    set_reconciliationNew_maintenanceSettlementQuery (state, data) {
      state.maintenanceSettlementQuery = data
    },
    set_reconciliationNew_notAllowReconciliationQuantity (state, data) {
      state.notAllowReconciliationQuantity = data
    },
    set_reconciliationNew_feeitemIds (state, data) {
      state.feeitemIds = data
    },
    set_reconciliationNew_billId (state, data) {
      state.billId = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default reconciliationNew
