<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div
        slot="bottom_box_content"
        style="padding:10px 10px;"
      >
        <component
          :is="curBottomComponent"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import reconciliationNewStoreModule from '@/store/modules/reconciliationNew'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 左侧操作栏
    leftComponent: () => import('./Left'),
    // 底部
    transactionreconciliationComponent: () => import('@/components/reconciliationNew/reconciliation/transaction/List'),
    transactionContractDetailComponent: () => import('@/components/reconciliationNew/reconciliation/transaction/Detail'),

    maintenancereconciliationComponent: () => import('@/components/reconciliationNew/reconciliation/maintenance/List'),

    transactionsettlementComponent: () => import('@/components/reconciliationNew/settlement/transaction/List'),
    maintenancesettlementComponent: () => import('@/components/reconciliationNew/settlement/maintenance/List'),
    settlementDetailComponent: () => import('@/components/reconciliationNew/settlement/common/settlementDetail'),
    // Detail: () => import('@/components/reconciliation/Detail'),

    // equityAmountDetail: () => import('@/components/reconciliation/EquityAmountDetail'),
    // equityAdjustmentDetail: () => import('@/components/reconciliation/EquityAdjustmentDetail'),

    // statementContractDetail: () => import('@/components/reconciliation/StatementContractDetail'),

    Right: () => null
  },
  data () {
    return {
    }
  },
  watch: {
    pageType (val) {
      // 清空便签项
      this.setLinkTagArray()

      if (val === 'settlementDetail') {
        this.setLinkTagArray([{ key: 'settlement', value: '结算单' }, { key: 'settlementDetail', value: '结算单详情' }])
      } else if (val === 'transactionContractDetail') {
        this.setLinkTagArray([{ key: 'reconciliation', value: '对账单' }, { key: 'transactionContractDetail', value: '合同详情' }])
      } else {
        this.setLinkTagArray([{ key: 'reconciliation', value: '对账单' }, { key: 'settlement', value: '结算单' }])
      }
      this.setActivedTagName(val)
      this.initTagEvent()
      this.reloadPageComponents()
    },
    moduleType (val) {
      this.reloadPageComponents()
    }
  },
  methods: {
    initTagEvent () {
      // 注册头部事件
      this.setLinkRouterMaps()
      var s = new Map([
        ['reconciliation', () => {
          this.$store.commit('set_reconciliationNew_pageType', 'reconciliation')
        }],
        ['settlement', () => {
          this.$store.commit('set_reconciliationNew_pageType', 'settlement')
        }],
        ['settlementDetail', () => {
          // this.$store.commit('set_reconciliationNew_pageType', 'reconciliation')
        }],
        ['transactionContractDetail', () => {
          // this.$store.commit('set_reconciliationNew_pageType', 'reconciliation')
        }]
      ])
      this.setLinkRouterMaps(s)
    },
    reloadPageComponents () {
      // 清空便签项
      if (this.pageType === 'settlementDetail') {
        this.setShowLeftBlock(false)
        this.setLeftComponent()
        this.setBottomComponent('settlementDetailComponent')
      } else if (this.pageType === 'transactionContractDetail') {
        this.setShowLeftBlock(false)
        this.setLeftComponent()
        this.setBottomComponent('transactionContractDetailComponent')
      } else {
        this.setShowLeftBlock(true)
        this.setLeftComponent('leftComponent')
        this.setBottomComponent(this.moduleType + this.pageType + 'Component')
      }
    }
  },
  computed: {
    pageType () {
      return this.$store.state.reconciliationNew ? this.$store.state.reconciliationNew.pageType : ''
    },
    moduleType () {
      return this.$store.state.reconciliationNew ? this.$store.state.reconciliationNew.moduleType : ''
    }
  },
  mounted () {
    this.setShowLeftBlock(true)
    this.setLeftComponent('leftComponent')
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    if (!this.$store.hasModule('reconciliationNew')) {
      this.$store.registerModule('reconciliationNew', reconciliationNewStoreModule)
      this.$store.commit('set_reconciliationNew_companyId', this.$store.getters.token.userInfo.companyId)
      this.$store.commit('set_reconciliationNew_publisherId', this.$store.getters.token.userInfo.publisherId)
      this.$store.commit('set_reconciliationNew_pageType', 'reconciliation')
    }
  },
  destroyed () {
    // 卸载reconciliation动态store模块
    this.$store.unregisterModule('reconciliationNew')
    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
